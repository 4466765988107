#infosCanvas{
    .offcanvas-header {
        padding: 5px;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1;
        justify-content: right;
    }
    &.offcanvas {
        background-color: white !important;
    }
    .offcanvas-body {
        overflow: hidden;
    }
    .offcanvas-body, .offcanvas-header {
        color: black;
    }
    .infos-body {
        label {
            color: grey;
        }
        p {
            margin-bottom: 10px;
            overflow-wrap: break-word;
        }
        font-size: 16px;
    }

}

@media screen and (min-height: 768px) {
    .infos-body {
        margin-right: 20px;
    }
}