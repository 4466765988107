.accordion {
  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
}

nav#main-menu {
  @include flex(1);
  font-family: 'Oswald';
  margin-right: 6px;
  padding: 0 4px 12px 12px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(#000,.2);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: rgba(#fff,1);
    &:hover {
      background: rgba(#fff,1);
      cursor: pointer;
    }
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .btn  {
    &[disabled] {
      display: none;
    }
  }
}
.indicators-menu {
  @extend %flexbox;
  @include flex-direction(column);
  padding-top: 4px;
  + .indicators-menu {
    margin-top: 20px;
  }
}
.indicator-menu {
  @extend %flexbox;
  margin-bottom: 6px;
  button {
    font-size: 18px !important;
  }
  &:before {
    content: "";
    @include flexbox();
    width: 4px;
    margin-right: 5px;
    border-radius: $global-radius;
  }
  &:last-child {
    margin-bottom: 0;
  }
  > div {
    @include flex(1);
  }
  &.indices {
    &:before,
    .btn.active,
    .accordion-button[aria-expanded="true"],
    .submenu-link.active span {
      background-color: rgba(#000, .4);
    }
  }
  &.vulnerabilite-systemique {
    button:first-of-type {
      font-size: 15px !important;
    }
    &:before,
    .btn.active,
    .accordion-button[aria-expanded="true"],
    .submenu-link.active span {
      @include gradient-directional(
        $deg: 160deg,
        $start-color: $vulnerabilite-systemique-color-1,
        $end-color: $vulnerabilite-systemique-color-2,
        $start-percent: 15%,
        $end-percent: 85%
      );
    }
  }
  &.risque {
    &:before,
    .btn.active,
    .accordion-button[aria-expanded="true"],
    .submenu-link.active span {
      @include gradient-directional(
        $deg: 160deg,
        $start-color: $risque-color-1,
        $end-color: $risque-color-2,
        $start-percent: 15%,
        $end-percent: 85%
      );
    }
  }
  &.aleas {
    &:before,
    .btn.active,
    .submenu-link.active span,
    .accordion-button[aria-expanded="true"]{
      @include gradient-directional(
        $deg: 160deg,
        $start-color: $aleas-color-1,
        $end-color: $aleas-color-2,
        $start-percent: 15%,
        $end-percent: 85%
      );
    }
  }
  &.enjeux {
    &:before, .btn.active,
    .accordion-button[aria-expanded="true"],
    .submenu-link.active span {
      @include gradient-directional(
        $deg: 160deg,
        $start-color: $enjeux-color-1,
        $end-color: $enjeux-color-2,
        $start-percent: 15%,
        $end-percent: 85%
      );
    }
  }
  &.gestion {
    margin-left: 9px;
    &:before, .btn.active,
    .accordion-button[aria-expanded="true"],
    .submenu-link.active span {
      @include gradient-directional(
        $deg: 160deg,
        $start-color: $gestion-color-1,
        $end-color: $gestion-color-2,
        $start-percent: 15%,
        $end-percent: 85%
      );
    }
  }
  &.reprensation {
    margin-left: 9px;
    &:before, .btn.active,
    .accordion-button[aria-expanded="true"],
    .submenu-link.active span {
      @include gradient-directional(
        $deg: 160deg,
        $start-color: $reprensation-color-1,
        $end-color: $reprensation-color-2,
        $start-percent: 15%,
        $end-percent: 85%
      );
    }
  }
  .btn, .accordion-button {
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    border: none;
    color: #fff;
    border-radius: $global-radius;
    background: transparent;
    box-shadow: none;
    font-size: 18px;
    font-weight: 500;
    padding: 6px 12px 6px 8px;
    &.active {
      background: rgba(0,0,0,.4);
      color: #fff;
      span + i {
        webkit-transform: rotate(90deg);
        transform: rotate(90deg)
      }
    }
    span + i {
      margin-left: 20px;
    }
  }
  .submenu {
    padding-left: 8px;
    overflow: hidden;
    .accordion-item {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      color: #fff;
      box-shadow: none;
      .accordion-header {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 6px;
        &.active a,
        a {
          @extend %flexbox;
          @include align-items(center);
          @include justify-content(space-between);
          text-decoration: none;
          color: #fff;
          border-radius: $global-radius;
          background: transparent;
          box-shadow: none;
          font-size: 18px;
          font-weight: 500;
          line-height: 1;
          padding: 6px 12px;
          &.collapsed {
            i {
              transform: rotate(0deg);
            }
          }
          i {
            transform: rotate(90deg);
          }
          &:hover {
            text-decoration: none;
          }
          button {
            display: none;
          }
        }
      }
      .sub-list {
        margin-bottom: 6px;
        .submenu-link {
          @include flexbox();
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
          position: relative;
          &:before {
            border-left: 3px solid rgba(#fff, .2);
            content: "";
            @include flexbox();
          }
          &.active {
            span {
              background-color: rgba(#000, .4);
            }
            &:before {
              border-left: 3px solid #fff;
              content: "";
              @include flexbox();
            }
            &:after {
              content: "";
              position: absolute;
              left: 3px;
              top: calc(50% - 4px);
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-left: 4px solid #fff;
              border-bottom: 4px solid transparent;
            }
            &:hover {
              &:before { border-color: #fff; }
              &:after { border-left-color: #fff; }
            }
          }
          span {
            margin-left: 10px;
            flex: 1;
            padding: 2px 6px;
            border-radius: 3px;
          }
          &:hover {
            &:before {
              border-left: 3px solid rgba(#fff, .4);
              content: "";
              @include flexbox();
            }
            &:after {
              content: "";
              position: absolute;
              left: 3px;
              top: calc(50% - 4px);
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-left: 4px solid rgba(#fff, .4);
              border-bottom: 4px solid transparent;
            }
          }
          &[disabled]:hover {
            &:before {border-left: 3px solid rgba(#fff, .2);}
            &:after {display: none;}
          }
          &[disabled] {
            color: rgba(#fff, .4);
            cursor: not-allowed;
          }
        }
      }
    }
  }

}

.submenu {
  padding-left: 8px;
  overflow: hidden;
  .accordion-item{
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: #fff;
    box-shadow: none;
    .accordion-body {
      padding: 0px;
    }
  }
  .accordion-header {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 500;
    .accordion-button{
      position: relative;
      display: flex;
      font-size: 18px !important;
      align-items: center;
      width: 100%;
      border-radius: 3px;
    }
  }
  .submenu-link {
    span {
      color: white;
    }
  }
}

.nav-btn-group {
  button {
    border-radius: 0px;
    border-right: 3px solid grey;

    color: white;
    background-color: #E13C3B;

  }
  button.active {
    background-color: white;
    color: black;
  }
  :last-child {
    border-right: 0px solid transparent;
  }
  button:hover{
    background-color: white;
  }
}