a, button {
  // @extend %flexbox;
  // @include align-items(center);
  // background: none;
  // border: none;
  // border-radius: 0;
  // text-decoration: none;
  // color: $first-color;
  // cursor: pointer;
  // transition: all 0.2s ease;
  // font-family: $font-family;
  // position: relative;
  &.btn {
    line-height: 1;
    border: none;
    box-shadow: none;
    &:focus {
      outline: none !important;
    }
  }
}

.btn-pdf {
  @extend %flexbox;
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  
  color: #fff;
  border: none;
  border-radius: $global-radius;
  background-color: $second-color !important;
  margin-top: 8px;
  i {
    margin-right: 8px;
  }
  &[disabled] {
    display: none !important;
  }
  &:hover,&:active,&:focus {
    color: #fff;
    background-color: lighten($second-color, 10%) !important;
  }
}

button.btn-primary, button[variant="primary"] {
  font-size: 18px;
  background-color: $first-color;
  width: 100%;
  border-radius: $global-radius;
  border: none;
  padding: 6px;
}

#loginModal {
  button {
    color: white;
    margin-top: 10px;
  }
  a {
    margin-right: 5px;
  }
}
