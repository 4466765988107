.list-group-item {
    padding: 5px 8px;
}

/* .panel .panel-body {
    padding: 1px 0px;
}

.panel-group {
	margin: 5px 0px;
}

.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-9 {
  padding: 6px;
  padding-left: 8px;
	padding-right: 8px;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 6px;
    padding-left: 6px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
} */

#deroulant{
  position: absolute;
  top: 25px;
  z-index: 10000;
}

#formControlsSelect{
  background-color: rgba(255, 255, 255, 0.3);
}

.loader {
    margin: 0 auto;
    border: 25px solid #f3f3f3;
    border-top: 25px solid rgba(201, 48, 44,1);
    border-right: 25px solid rgba(236, 151, 31,1);
    border-bottom: 25px solid rgba(68, 157, 68,1);
    border-left: 25px solid rgba(49, 176, 213,1);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 6s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
