// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Global
$global-radius: 3px;
$global-padding: 20px;
$global-weight: 500;

// General
$first-color: #155e79;
$second-color: #121e2c;
$third-color: #de3c30;

$vulnerabilite-systemique-color-1: #353535;
$vulnerabilite-systemique-color-2: #616161;

$risque-color-1: #e1723b;
$risque-color-2: #e0963b;

$aleas-color-1: #E13C3B;
$aleas-color-2: #E03C3B;

$enjeux-color-1: #F7971E;
$enjeux-color-2: #F7B733;

$gestion-color-1: #11998E;
$gestion-color-2: #25AF59;

$reprensation-color-1: #0083B0;
$reprensation-color-2: #00B4DB;

// Text
$first-txt-color: #000;
$second-txt-color: #fff;
$error-color: #d23129;

$sidebar-width: 260px;

.full-width {width: 100%;}
.full-height {
  height: 100%;
  .flex-0 { @include flex(0); }
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
