
.olTool {
  position: relative;
  background-color: #fff;
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 10px 10px 9px;
  border-radius: $global-radius;
  border: 1px rgba(255, 255, 255, 0.5);
  bottom: 28px;
  left: -50px;
  min-width: 150px;
  white-space: nowrap;
  font: 12pt sans-serif;
  h6 {
    margin: 0;
    line-height: 1;
    color: #000 !important;
  }
}

.olTool:after, .olTool:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.olTool:after {
  border-top-color: #fff;
  border-width: 10px;
  left: 40px;
  margin-left: -10px;
}

@media (max-width: 768px) {
  .olTool {
    display: none;
  }
}