.menu-btn {
  padding: 10px;
  border-radius: 3px;
}
.rotate-menu-on {
  transform: rotate(90deg);
  transition: all .2s linear;
}

.rotate-menu-off {
  transform: rotate(0deg);
  transition: all .2s linear;
}

#sidebar {
  @extend %flexbox;
  @include flex-direction(column);
  @include flex(0 0 $sidebar-width);

  @include gradient-directional(
    $deg: 160deg,
    $start-color: $second-color,
    $end-color: $first-color,
    $start-percent: 15%,
    $end-percent: 85%
  );

  z-index: 2;
  #map-title {
    color: #fff;
  }
}

#sidebar-header {
  padding: 0 12px;
  background-color: transparent !important;
  button {
    border: 1px solid white !important;
    &:hover {
      background-color: transparent;
      color: white;
      border: 0px solid transparent;
    }
  }
}
#sidebar-title {
  display: block;
  @include align-items(center);
  color: $second-txt-color;
  font-size: 16px;
  font-weight: 500;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 5px 8px 7px;
  margin-bottom: 14px;
  i { margin-right: 10px; }
}

#sidebar-logo {
  cursor: pointer;
  width: 100%;
  margin: 0px;
  height: 76px;
  background-image: url("../../images/logo-osi.png");
  background-repeat: no-repeat;
  display: inline-block;
}

#sidebar-footer {
  padding: 4px 0;
  margin: auto;
}

blockquote {
position: absolute;
background: red;
top: 0;
z-index: 9999;}

.number-indicator {
  display: none;
}


@media screen and (max-width: 768px) {
  
  #sidebar{
      width:100%;
      flex: 0!important;
  }
  
  #sidebar-footer {
      display: none;
  }
}
  