body, #root, #layout {
  min-height: 100vh;
  max-height: 100vh;
  margin: 0px;
}
#layout {
  @extend %flexbox;
  @include flex-direction(column);
  overflow: hidden;
  main {
    @extend %flexbox;
    @include flex(1);
    overflow: hidden;
    #map-container {
      @include flex(1);
    }
  }
}


body {
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $first-txt-color;
}

@media screen and (max-width: 768px) {
  #layout main{
      flex-wrap: nowrap;
      flex-direction: column;
  }
  .map-wrapper {
    height: 50rem!important;
  }

  .ol-overlaycontainer-stopevent .ol-scale-line {
    background-color: transparent;
    padding: 0;
    position: fixed;
    left: 10px;
    bottom: 10px;
    .ol-scale-line-inner {
      background: rgba(0, 0, 0, .15);
      border-color: #333;
      color: #333;
      margin: 0;
    }
  }
}