#Footer {
  @extend %flexbox;
  @include flex-direction(column);
  background: #fff;
  .footer-licence {
    @extend %flexbox;
    @include align-items(center);
    @include flex(1);
    padding: 6px 10px;
    .licence-logo {
      margin-right: 6px;
    }
    .licence-text {
      font-size: 12px;
      font-weight: 300;
    }
  }
  .footer-terms {
    margin-right: 10px;
  }
  .footer-partners {
    @extend %flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    padding: 6px 10px;
    > div {
      @include justify-content(flex-end);
      @include align-items(center);
    }
    a {
      margin-right: 10px;
    }
    img {
      max-height: 40px;
    }
  }
}
