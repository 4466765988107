.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 2;
    background-color: rgba(#07111C, .8) !important;
  }
}

#credits, #imgLogin {
  a {
    margin-right: 5px;
    img {
      //max-width: 100px;
      height: 75px;
      padding: 4px;
    }
  }
}
.modal-overlay {
  @extend %flexbox;
  @include justify-content(center);
  @include align-items(center);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px;
  .modal-wrapper {
    background-color: #fff;
    border-radius: $global-radius;
    padding: 20px;
    max-height: 100%;
    overflow: auto;
    @include media-breakpoint-up(xl) {
      padding: 20px 60px;
    }
    &:focus {
      outline: none;
    }
    .login-title {
      font-size: 26px;
      color: $first-color;
      margin-bottom: 30px;
      text-align: center;
    }
    .modal-logo {
      width: 198px;
      height: 44px;
      margin: 20px auto 10px;
      background-image: url("../../images/logo-osi-modal.png");
      background-repeat: no-repeat;
    }
    .modal-separator {
      background-color: $first-color;
      width: 120px;
      height: 1px;
      margin: 10px auto 10px;
    }
    .text-intro {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      margin-bottom: 16px;
      // @include media-breakpoint-up(md) {
      //   column-count: 2;
      //   column-gap: 30px;
      // }
      @include media-breakpoint-up(lg) {
        column-count: 1;
        font-size: 15px;
        line-height: 22px;
      }
      @include media-breakpoint-up(xl) {
        column-count: 2;
        column-gap: 30px;
      }
    }
  }
}

.browser-support {
  @extend %flexbox;
  @include align-items(center);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #D85606;
  font-size: 14px;
  line-height: 18px;
  background-color: #F9E9DF;
  border: 1px solid #C7AB99;
  .logo-wrapper {
    padding: 8px 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(#000,.1);
    margin-left: 10px;
    transition: all .5s;
    @extend %flexbox;
    @include justify-content(center);
    @include align-items(center);
    &:hover {
      box-shadow: 0 3px 18px rgba(#000,.4);
    }
  }
  .logo-chrome {
    width: 93px;
    height: 26px;
    background-image: url('../../images/logo-chrome.png');
    background-repeat: no-repeat;
  }
}
