.map-wrapper {
  height: 100%;
}
#map-container,
.map-wrapper {
  position: relative;
}

.mapDiv {
  display: flex;
}

.map, .mapDiv{
  height: 100%;
  position: relative;
}

.select-year-slider-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  width: 55%;
  background: #fff;
  box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 10px 10px;
  padding: 10px 30px 30px 30px;
  .select-year-slider {
    position: relative;
    .rc-slider-handle {
      border-color: #fff !important;
      background-color: #1583AF !important;
      width: 16px;
      height: 16px;
      margin-top: -7px;
      &:focus {
        box-shadow: none;
      }
    }
    .rc-slider-mark-text {
      color: #124156;
      transform: rotate(45deg) !important;
    }
    .rc-slider-mark-text-active {
      color: #1583AF;
      font-weight: 500;
      font-size: 14px;
    }
    .rc-slider-rail {
      background-color: rgba(#124156,.6) !important;
      height: 2px;
    }
    .rc-slider-dot {
      width: 10px;
      height: 10px;
      bottom: -2px;
      background-color: #124156;
      border-color: #fff !important;
    }
  }
}

.ol-overlaycontainer-stopevent {
  .ol-zoom {
    position: absolute;
    top: 8px;
    right: 8px;
    left: auto;
    @extend %flexbox;
    @include flex-direction(column);
    background-color: #fff;
    box-shadow: 0 2px 8px -1px rgba(0,0,0,.2);
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
    button {
      color: #000;
      border: none;
      background-color: transparent;
      width: 30px;
      font-size: 24px;
      font-weight: 500;
      margin: 0;
      &:hover {
        background-color: #efefef;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .ol-rotate,
  .ol-attribution {
    display: none;
  }
  // non responsif
  @media screen and (min-width: 769px) {
    .ol-scale-line {
      position: absolute;
      top: 10px;
      right: 50px;
      left: auto;
      background-color: transparent;
      padding: 0;
      .ol-scale-line-inner {
        background: rgba(0, 0, 0, .15);
        border-color: #333;
        color: #333;
        margin: 0;
      }
    }
  }
}

.charts-container {
  margin-top: 6px;
  .highcharts-container {
    width: auto !important;
    height: 240px !important;
  }
  .highcharts-credits {
    display: none;
  }
  .highcharts-bar-series {
    .highcharts-label {
      text { display:none; }
    }
  }
}
.data-container {
  position: absolute;
  top: 14px;
  left: 14px;
  background-color: rgba($second-color, .6);
  border-radius: $global-radius;
  padding: 10px;
  width: 250px;
}

.data-block-title {
  @extend %flexbox;
  @include align-items(center);
  color: #fff;
  font-size: 22px;
  margin-bottom: 10px;
  i {
    margin-right: 10px;
    font-size: 20px;
  }
}
.data-block-container {
  background-color: #fff;
  border-radius: $global-radius $global-radius 0 0;
  padding: 8px;
  + .data-block-container {
    border-radius: 0 0 $global-radius $global-radius;
  }
  a {
    text-decoration: none;
  }
  .data-value {
    margin-bottom: 3px;
    &.min {
      div + div {
        font-size: 16px;
      }
    }
  }
  .data-value-name { font-size: 20px; }
  .data-value-desc { font-size: 16px; }
  .data-value-label {
    opacity: .6;
    + div {
      font-size: 18px;
      ul {
        font-size: 14px;
        list-style: circle;
        margin-left: 10px;
        padding-left: 14px;
      }
    }
  }
}
.data-block-methode .data-block-container {
  border-radius: $global-radius;
}
.data-block-metadonnees .data-block-container {
  margin-top: -3px;
  border-radius: 0 0 $global-radius $global-radius;
}

.data-block {
  .btn[disabled] {
    display: none;
  }
}

#map-container {
  #comm-title {
    display: none;
  }
}


#infoBtn {
  top:128px;
  right: 8px;
	float: right;
  color: #363636;
  width: 30px;
  margin: 0;
  background-color: white;
  font-size: 18;
  right: 8px;
  box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  height: 30px;
  position: absolute;
}

#infos-buttons {
  bottom: 5px;
  right: 5px;
  z-index: 1;
  position: fixed;
  button {
    margin-left: 5px;
  }
  button:hover {
    background-color: #213750!important;
  }
}

.legendImg {
  img {
    width: 100%;
  }
}
