#baseMapsSelector {
    position: absolute;
    top: 90px;
    right: 8px;

	float: right;
    color: #363636;
    width: 30px;
    margin: 0;
    background-color: white;
    font-size: 18;
    right: 8px;
    box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    height: 30px;
}

.basemapItems .card {
    margin-bottom: 10px;
    border: 1px solid rgba(128,128,128, 0.4);
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 15;
}

#baseMapsSelector svg {
    height: 15px;
}

#baseLayerPreviewer {
    max-width: 120px;
    //height: 65%;
    top: 35px;
    right: 50px;
	float: right;
	position: fixed;
    background-color: rgba(255,255,255, 0.9);
    border-radius: 10px;
    font-size: 12px;
    padding:0;
    .card {
        &.active {
            border: 2px solid rgba(128,128,128,1);
        }

        .card-body {
            padding: 2px;
        }
    }
    .basemapItems {
        margin-top: 10px;
        overflow: auto;
        //height: 80%;
        //max-height: 85%;
        margin-bottom: 10px;
        padding-bottom: 0px;
    }
}

@media screen and (max-height: 650px) {
    #baseLayerPreviewer .basemapItems {
       height:13vw;
    }
}

.opacity-container {
    font-size: 18px;
    padding-bottom: 0px;
    label {
        margin-top: 10px;
    }
}
